var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-h5 font-weight-medium",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
              attrs: { cols: "6", md: "9", lg: "10" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("homeOwners")) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "text-none rounded-lg",
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  outlined: "",
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(_vm._s(_vm.$t("export"))),
                              _c("v-divider", {
                                staticClass: "mx-2 my-n1 primary",
                                attrs: { vertical: "" },
                              }),
                              _c("v-icon", [_vm._v("mdi-chevron-down")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        _vm._l(_vm.exportFileExtensions, function (extension) {
                          return _c(
                            "v-list-item",
                            { key: extension },
                            [
                              _c(
                                "v-list-item-content",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportData(extension)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t(extension)))]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pr-0" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-subtitle-2 my-auto",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "ml-1",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("homeOwners")) +
                          " : " +
                          _vm._s(_vm.usersData.length)
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6",
                      attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-lg",
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          label: _vm.$t("search"),
                          "single-line": "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          "background-color": "grey lighten-4",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0 pa-0",
            class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
            attrs: {
              headers: _vm.headers,
              items: _vm.usersData,
              "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
              search: _vm.search,
              "item-key": "email",
              color: "primary",
              "checkbox-color": "primary",
              "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
              loading: _vm.usersData.length == 0,
              "loading-text": "Loading... Please wait",
              "mobile-breakpoint": "950",
              "disable-sort": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-subtitle-2 font-weight-bold ma-0",
                        },
                        [
                          _c("a", { staticClass: "primary--text" }, [
                            _vm._v(" " + _vm._s(item.name)),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.state",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(item.state ? item.state : "-") + " "),
                    ]
                  },
                },
                {
                  key: "item.center",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.center) + " ")]
                  },
                },
                {
                  key: "item.created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.created_at) + " ")]
                  },
                },
                {
                  key: "item.status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: _vm.getColor(item.status),
                            small: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.access",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "primary--text",
                          on: {
                            click: function ($event) {
                              return _vm.impersonateUser(item.user_id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("signIn")))]
                      ),
                    ]
                  },
                },
                !_vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "body",
                      fn: function (ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                { key: item.email, staticClass: "pb-4" },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: { height: "220", elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(item.name)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { staticClass: "pr-0" },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "white--text mr-4",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: _vm.getColor(
                                                      item.status
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(item.status)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("type")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(_vm._s(item.type))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("state")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(_vm._s(item.state))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("center")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(_vm._s(item.center))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("created")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.created_at)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("updated")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.updated_at)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("created_at")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.created_by)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("adminAccess")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "primary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("signIn"))
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }