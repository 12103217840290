<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col
        cols="6"
        md="9"
        lg="10"
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("homeOwners") }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              outlined
              class="text-none rounded-lg"
              v-on="on"
              >{{ $t("export")
              }}<v-divider vertical class="mx-2 my-n1 primary"></v-divider
              ><v-icon>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="extension in exportFileExtensions"
                :key="extension"
              >
                <v-list-item-content @click="exportData(extension)">{{
                  $t(extension)
                }}</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-divider></v-divider>
    </v-row>
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0">
        <v-row>
          <span
            class="text-subtitle-2 my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1'"
            >{{ $t("homeOwners") }} : {{ usersData.length }}</span
          >
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="usersData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        item-key="email"
        color="primary"
        class="elevation-0 pa-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        checkbox-color="primary"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="usersData.length == 0"
        loading-text="Loading... Please wait"
        mobile-breakpoint="950"
        disable-sort
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row class="text-subtitle-2 font-weight-bold ma-0">
            <a class="primary--text"> {{ item.name }}</a>
          </v-row>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.state ? item.state : "-" }}
        </template>
        <template v-slot:[`item.center`]="{ item }">
          {{ item.center }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small class="white--text">
            {{ $t(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.access`]="{ item }">
          <a class="primary--text" @click="impersonateUser(item.user_id)">
            {{ $t("signIn") }}</a
          >
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.email" class="pb-4">
              <v-card height="220" elevation="0">
                <v-row>
                  <v-col cols="7" class="ml-1">
                    <span class="text-subtitle-2 font-weight-bold">
                      <a class="primary--text"> {{ item.name }}</a>
                    </span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="pr-0">
                    <v-chip
                      x-small
                      :color="getColor(item.status)"
                      class="white--text mr-4"
                    >
                      {{ $t(item.status) }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("type") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{ item.type }}</v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("state") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    item.state
                  }}</v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("center") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    item.center
                  }}</v-col>
                </v-row>

                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("created") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.created_at }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("updated") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.updated_at }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("created_at") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.created_by }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("adminAccess") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    <a class="primary--text"> {{ $t("signIn") }}</a></v-col
                  ></v-row
                >
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
import { exportDataToFile } from "@/store/utils/utils";
export default {
  name: "HomeOwners",
  mixins: [UserDataMixin],
  props: {},
  data() {
    return {
      search: "",
      headers: [
        {
          text: "User",
          value: "name",
        },
        { text: "State", value: "state" },
        { text: "Center", value: "center" },
        { text: "Created On", value: "created_at" },
        { text: "Updated On", value: "updated_at" },
        { text: "Created By", value: "created_by" },
        { text: "Status", value: "status" },
        { text: "Admin access", value: "access" },
      ],
      usersData: [],
      exportFileExtensions: ["xls", "xlsx", "csv"],
    };
  },
  computed: {
    centerMap() {
      return this.$store.state.User.centerMap;
    },
  },
  watch: {
    centerMap() {
      this.getUsers();
    },
  },
  created() {
    this.moment = moment;
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.usersData = this.$store.state.User.homeOwners;
      if (Object.keys(this.centerMap).length > 0) {
        const userData = await this.$store.dispatch("getHomeOwners");
        if (userData.status !== "error") {
          userData.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          userData.forEach((item) => {
            item.center = item.center_id
              ? this.centerMap[item.center_id]?.center_name
              : "-";
            item.created_at = this.moment(item.created_at).format(
              "MM/DD/YYYY hh:mm A",
            );
            item.updated_at = this.moment(item.updated_at).format(
              "MM/DD/YYYY hh:mm A",
            );
            item.created_by =
              item.created_by === "00000000-0000-0000-0000-000000000000" ||
              !item.created_by
                ? this.$t("self")
                : item.created_by;
          });
          this.$store.commit("setHomeOwners", userData);
          this.usersData = userData;
        }
      }
    },
    getColor(status) {
      if (status.toLowerCase() === "pending") return "#C35309";
      else if (status.toLowerCase() === "inactive") return "#616161";
      else return "#1E853A";
    },
    exportData(extension) {
      const exportData = this.usersData.map((user) => {
        return {
          User: user.name,
          State: user.state,
          Center: user.center,
          "Created On":
            user.created_at === "Invalid date" ? "-" : user.created_at,
          "Updated On":
            user.updated_at === "Invalid date" ? "-" : user.updated_at,
          "Created By": user.created_by,
          Status: this.$t(user.status),
        };
      });
      exportDataToFile(exportData, "Homeowners", extension);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-list-item {
  cursor: pointer;
}
</style>
